<template>
  <div
    v-if="!submissionCompleted"
    class="lg:pb-1 md:pb-1 pb-4 flex w-full flex-col gap-4 px-5 bg-red"
  >
    <div
      class="flex items-center lg:justify-start md:justify-start justify-between"
    >
      <div
        class="cursor-pointer px-3 py-1 flex items-center gap-1 text-primary"
        @click="$router.go(-1)"
      >
        <vs-icon size="20px" icon="arrow_back"></vs-icon>
        <p class="text-lg">Back</p>
      </div>
      <vs-button
        size="normal"
        @click="saveData"
        :disabled="isCreatingOrUpdating"
        class="py-3 lg:hidden md:hidden block"
      >
        <div class="flex items-center justify-center gap-1">
          <vs-icon
            v-if="isCreatingOrUpdating"
            icon="refresh"
            class="animate-spin"
          ></vs-icon>
          {{ btnLabel }}
        </div>
      </vs-button>
    </div>
    <div class="w-full flex">
      <vs-input
        val-icon-success="done"
        val-icon-danger="error"
        :danger="errors.first('basic.consent_form') ? true : false"
        v-validate="'required'"
        name="consent_form"
        data-vv-as="Consent Form Name"
        data-vv-scope="basic"
        :danger-text="errors.first('basic.consent_form')"
        placeholder="Name your consent form"
        :disabled="localConsentFormInfo.isDefault"
        v-model="localConsentFormInfo.name"
        class="lg:w-1/3 md:w-1/3 w-full px-2 text-base"
        label="Name your consent form"
      />
      <vs-button
        size="normal"
        @click="saveData"
        :disabled="isCreatingOrUpdating"
        class="lg:w-1/12 md:w-1/5 w-1/3 py-3 lg:block md:block hidden save-btn"
      >
        <div class="flex items-center justify-center gap-1">
          <vs-icon
            v-if="isCreatingOrUpdating"
            icon="refresh"
            class="animate-spin"
          ></vs-icon>
          {{ btnLabel }}
        </div>
      </vs-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isCreatingOrUpdating: false,
      localConsentFormInfo: {
        name: "",
        isDefault: false,
      },
    };
  },
  methods: {
    async saveData() {
      const isValid = await this.$validator.validateAll("basic");
      await this.$validator.validate();
      if (isValid) {
        this.$store.dispatch(
          "consentForms/updateConsentFormDetails",
          this.localConsentFormInfo
        );
        this.$store.dispatch("consentForms/updatedFormSubmission", true);
      }
    },
  },
  computed: {
    btnLabel() {
      if (this.$route.name.search("Create") !== -1) return "Save";
      return "Update";
    },
    consentFormInfo() {
      return this.$store.getters["consentForms/getConsentForm"];
    },
    isSubmitting() {
      return this.$store.getters["consentForms/isFormSubmitting"];
    },
    submissionCompleted() {
      return this.$store.getters["consentForms/isSubmissionCompleted"];
    },
  },
  watch: {
    consentFormInfo: {
      handler(newConsentFormInfo) {
        this.localConsentFormInfo = { ...newConsentFormInfo };
      },
      immediate: true,
    },
    isSubmitting: {
      handler(disableBtn) {
        this.isCreatingOrUpdating = disableBtn;
      },
    },
  },
};
</script>
<style>
.save-btn {
  margin-top: 20px;
}
</style>