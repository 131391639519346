<script>
import { mapActions, mapMutations } from "vuex";
import jwt_decode from 'jwt-decode';
  export default {
    data() {
      return {
        /**
         * Allowed Routes:
         * Shop, Clinic, Settings, Staff, Compliance, Invoice, Drug, POS, Insights, CustomConsentForm, CustomAftercareDocument, CustomProduct
         */
        allowedRoutes:['Shop', 'Drug', 'Settings', 'Clinic', 'Staff', 'Compliance', 'Invoice', 'POS', 'Insights', 'CustomConsentForm', 'CustomAftercareDocument', 'CustomProduct'],
        showlanding: false,
      }
    },
    async created() {
      this.userRole = this.$store.state.AppActiveUser.userRole.toLowerCase();
      this.organizationId = localStorage.getItem(
      `${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`
      );
      this.token = this.$route.query.token
      this.embedClinicId = this.$route.query.clinicId
      this.SET_BEARER(this.$route.query.token)
      if(this.token && this.token.length>0){
        this.userId = jwt_decode(this.token).id;
      }
      else{
        this.userId = JSON.parse(
          localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`)
        )._id;
      }
      if(this.embedClinicId && this.embedClinicId.length>0){
        this.organizationId = await this.fetchOrgIdByClinic();

        localStorage.setItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}EmbededData`, JSON.stringify({
          token: this.token,
          organizationId:this.organizationId,
          clinicId: this.embedClinicId
        }));
      }
    },
    mounted() {
      this.redirectBackIfNotPermitted()
    },
    methods: {
      ...mapActions("drugbook", ["getAssociatedClinics"]),
      ...mapActions("clinic", ["fetchClinicsDetail"]),
      ...mapMutations("admin",["SET_BEARER"]),
      async getUserPermissions() {
        try {
          const user = this.$store.state.AppActiveUser

          let isDocEMR = false
          if (user && this.organizationId) {
            const res = await this.$store.dispatch('organization/fetchOrganizationUser', {
              userId: user.id,
              orgId: this.organizationId,
            })
            const userInfo = res.data.data[0]
            isDocEMR = (userInfo.doctorEMRClinics || []).find((i) => {
              return (userInfo.associatedClinicIds || []).some((ii) => ii.clinicId === i.clinicId)
            })
          }

          let {data, status} = await this.$store.dispatch('admin/fetchUserPermission', {
            userId: user.id,
            organizationId: this.organizationId,
          })

          if (status != 200) return []

          let isDrugbookEnabled = false;
          let isInventoryEnabled = false;
          let isAnalyticsEnabled = false;
          let hasDrugEntry = null;

          if(this.organizationId) {
              const organizationStatus = await this.getOrganizationComplianceStatus();
              isAnalyticsEnabled = organizationStatus.isAnalyticsEnabled;
              isDrugbookEnabled = organizationStatus.isDrugbookEnabled;
              isInventoryEnabled = organizationStatus.isInventoryEnabled;
              hasDrugEntry = await this.checkClinicDrugEntry();
          }

          if (!isAnalyticsEnabled) {
            this.allowedRoutes = [
              'Shop', 'Drug', 'Settings', 'Clinic', 'Staff', 'Compliance',
              'Invoice', 'POS', 'CustomConsentForm', 'CustomProduct'
            ]
          }

          let permissionsList = data.map((item) => item.permission.name).filter(permission => this.allowedRoutes.includes(permission))
          this.$store.commit('admin/SET_USER_PERMISSION', permissionsList)

          const permissionMenus = this.permissionMenuItems.filter((menu) => {
            // if no permission key, default menu
            if (!menu.permission) {
              if (menu.slug === "referral" && this.isDoctorEMR()) {
                return;
              }
              return menu;
            }

            if (menu.header === 'Settings') {
              const isCustomProductPermiExist = permissionsList.find((permi) => permi === 'CustomProduct')
              const isCustomConsentPermiExist = permissionsList.find((permi) => permi === 'CustomConsentForm')
              const isCustomAftercarePermiExist = isDocEMR
              const isClinicPermiExist = permissionsList.find((permi) => permi === 'Clinic')
              const isStaffPermiExist = permissionsList.find((permi) => permi === 'Staff')

              // Remove clinic menu if permission is off
              const clinicIndex = menu.items.findIndex((child) => child.name === 'Clinics')
              if (!isClinicPermiExist && clinicIndex !== -1) {
                menu.items.splice(clinicIndex, 1);
              }
              // Remove staff menu if permission is off
              const staffIndex = menu.items.findIndex((child) => child.name === 'Staff')
              if (!isStaffPermiExist && staffIndex !== -1) {
                menu.items.splice(staffIndex, 1);
              }

              // Remove consent form menu if permission is off
              const consentFormIndex = menu.items.findIndex((child) => child.name === 'Consent Forms')
              if (!isCustomConsentPermiExist && consentFormIndex !== -1) {
                menu.items.splice(consentFormIndex, 1);
              }

              // Remove aftercare doc menu if permission is off
              const aftercareDocIndex = menu.items.findIndex((child) => child.name === 'Aftercare Docs')
              if (!isCustomAftercarePermiExist && aftercareDocIndex !== -1) {
                menu.items.splice(aftercareDocIndex, 1);
              }

              /**
               * 1. Remove custom product list menu(permission off)
               * 2. Remove product list menu if custom product list permission is on
               */
              const customProductListIndex = menu.items.findIndex((child) => child.name === `${user.userType} Custom Products List`)
              if (!isCustomProductPermiExist && customProductListIndex !== -1) {
                menu.items.splice(customProductListIndex, 1);
              }
              const productListIndex = menu.items.findIndex((child) => child.name === `${user.userType} Products List`)
              if (isCustomProductPermiExist && productListIndex !== -1) {
                menu.items.splice(productListIndex, 1);
              }

              /**
               * Show other menu even setting permission is off
               * - Clinic List
               * - Staff List
               * - Consent Form
               * - Aftercare Documents [AU only]
               * - Custom Product List
               */
              if (
                !permissionsList.includes(menu.permission) &&
                this.allowedRoutes.includes(menu.permission) &&
                (
                  isStaffPermiExist || isClinicPermiExist ||
                  isCustomConsentPermiExist || isCustomAftercarePermiExist ||
                  isCustomProductPermiExist
                )
              ) {
                const excludedMenu = [`${user.userType}-treatment-areas`, `${user.userType}-products-list`]
                menu.items = menu.items.filter((child) => !excludedMenu.includes(child.slug))

                return menu.items.length
              }
            }

            if (menu.header === 'Compliance') {
              const isDrugPermiExist = permissionsList.find((permi) => permi === 'Drug')
              const isCompliancePermiExist = permissionsList.find((permi) => permi === 'Compliance')

              /**
               * Hide Inventory & Drugbook if Drug permission is off
               * and if clinic has no drug entry or
               * if drugbook is disabled in clinics
               * or if it is an EMR doctor
               */
              if (!((isDrugPermiExist ||hasDrugEntry) && isDrugbookEnabled) &&
                  !((isDrugPermiExist || !hasDrugEntry) && isDrugbookEnabled) &&
                  !((isDrugPermiExist ||hasDrugEntry) && !isDrugbookEnabled) || this.isDoctorEMR()
              ) {
                menu.items = menu.items.filter((item) => !['Inventory', 'Drugbook'].includes(item.name))
              }
              if(!isInventoryEnabled){
                menu.items = menu.items.filter((items) => items.name !== "Inventory");
              }

              // Hide Compliance if Compliance permission is off
              if (!isCompliancePermiExist) {
                menu.items = menu.items.filter((item) => item.name !== 'Compliance')
              }

              if (menu.items.length === 0) {
                return;
              }

              return isCompliancePermiExist || ( hasDrugEntry || isDrugPermiExist )
            }

            return this.$store.state['admin'].permissions.includes(menu.permission) &&
                    this.allowedRoutes.includes(menu.permission)
          })
          return permissionMenus || []
        } catch(e) {
          /**
           * For users(doctor, nurse, basic & org-owner) not assigned to any orgs
           *
           **/
          const permissionMenus = this.permissionMenuItems.filter((menu) => {
            if (!menu.permission) return menu;

            if (menu.header === 'Settings' && menu.items.length > 0) {
              menu.items = menu.items.filter((childMenu) => !['Staff', 'Clinics'].includes(childMenu.name))
              return menu
            }
          })

          return permissionMenus || []
        }
      },
      async redirectBackIfNotPermitted() {
        let allowedPages = [];
        const userPermission = await this.getUserPermissions()
        userPermission.map(el => {
          if (el.items) {
            const subMenu = el.items.map(el => el.permission)
            allowedPages = [...allowedPages, ...subMenu]
          } else {
            allowedPages.push(el.permission)
          }
        })
        let currentPagePermission = this.$route.meta.permission
        const docEMRrestrictedPageNames = ["referral", "DoctorDrugbook", "DoctorDrugbookTransfer", "DoctorDrugbookEntries", "DoctorDrugbookLanding", "DoctorInventory"] // Pages restricted for doctor EMR
        if (this.isDoctorEMR() && docEMRrestrictedPageNames.includes(this.$route.name)) {
          this.$router.push(
            process.env.VUE_APP_REGION !== "US"
              ? "/doctor/notice-board"
              : "/doctor/treatment-history"
          );
        }
        if (currentPagePermission) {
          if (!allowedPages.includes(currentPagePermission)) {
            if (this.userRole == 'nurse') {
              this.$router.push(
                  process.env.VUE_APP_REGION !== "US"
                    ? "/nurse/notice-board"
                    : "/nurse/patients"
                );
            } else if (this.userRole == 'basic') {
              this.$router.push(
                  process.env.VUE_APP_REGION !== "US"
                    ? "/basic/notice-board"
                    : "/basic/patients"
                );
            } else {
              this.$router.push(
                  process.env.VUE_APP_REGION !== "US"
                    ? "/doctor/notice-board"
                    : "/doctor/treatment-history"
                );
            }

          }
        }
      },
      async checkClinicDrugEntry() {
        const { data } = await this.$store.dispatch(
          "drugbook/checkClinicDrugEntries",
          this.organizationId
        );

        return data.data;
      },
      async getOrganizationComplianceStatus() {
        const { data } = await this.$store.dispatch(
          "organization/fetchOrganizationDetails",
          this.organizationId
        );
        return data.data;
      },
      redirectToEntries() {
        if ( localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}EmbededData`)){
          this.$router.push({ path: "/drugbook/entries", query:{
            token: this.token,
            clinicId: this.embedClinicId
          } });
        }else{
          this.$router.push({ name: this.getRoleRouteName("DrugbookEntries") });
        }
      },
      async fetchOrgIdByClinic(){
        const res = await this.fetchClinicsDetail(this.embedClinicId)
            const clinicsDetail = res.data.data
            const orgId = clinicsDetail.organizationDetail._id
            return orgId;
      },
      async fetchclinics() {
        return new Promise(async (resolve, reject) => {
          try {
            this.$vs.loading();
            const res = await this.getAssociatedClinics({
              userId: this.userId,
              orgId: this.organizationId,
            });
            const clinicsList = res.data.data.map((item) => {
              return {
                clinicId: item._id,
                name: item.clinicName,
                clinicsCount: item.clinicsCount,
                drugEntries: item.drugEntries,
              };
            });
            this.$vs.loading.close();
            if (
              clinicsList
                .map((clinic) => clinic.drugEntries)
                .reduce((total, number) => total + number, 0) > 0
            ) {
              this.redirectToEntries();
            }
            else{
              this.showlanding=true;
            }
            resolve();
          } catch (err) {
            reject(err);
          }
        })
      },
      isDoctorEMR() {
        if (this.$store.state.AppActiveUser.userRole.toLowerCase() !== "doctor") return false;
        return !!this.$store.state.AppActiveUser.doctorEMRClinics.length;
      },
    }
  }
</script>
