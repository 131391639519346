<!-- =========================================================================================
  File Name: TheNavbar.vue
  Description: Navbar component
  Component Name: TheNavbar
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div class="sticky">
    <!-- class="vx-navbar-wrapper" -->
    <div
      class="p-0 md:p-5 bg-white"
      :style="
        $store.state.AppActiveUser.userType === 'basic' ? '' : 'width: 100%;'
      "
      :class="classObj"
    >

      <div v-if="showCloseCta" class="title flex items-center justify-between relative p-3">
        <button @click="closeIframeView" class="cursor-pointer p-3 border-none bg-transparent">
          <feather-icon icon="ArrowLeftIcon" class="text-primary2" />
        </button>
        <template v-if="showTitle">
          <h1 class="text-xl font-semibold"> Resource Hub </h1>
        </template>
        <template v-if="isIframe && showCart">
            <cart-drop-down />
        </template>
      </div>

      <vs-navbar
        v-if="!isIframe"
        class=" navbar-custom shop-nav"
        :color="navbarColorLocal"
        :class="{'p-0':isShopPages, textColor, 'px-0':isCreateOrUpdateConsentPage || isCreateOrUpdateAftercareDocs}"
      >
      <div class="w-full bg-white" >
        <div class="flex justify-between items-center w-full padder z-40 bg-white" style="height:46px;"
          :class="{'px-5 pt-4':isShopPages, 'xl:justify-end':!(isShopPages || isCreateOrUpdateAftercareDocs || isCreateOrUpdateConsentPage), 'pt-8 lg:pt-0 md:pt-0':isCreateOrUpdateAftercareDocs ||  isCreateOrUpdateConsentPage}">
          <!-- SM - OPEN SIDEBAR BUTTON -->
          <div class=" flex">
            <feather-icon
              class="sm:inline-flex xl:hidden cursor-pointer p-2 pl-0  text-primary2 "
              icon="MenuIcon"
              @click.stop="showSidebar"
              :class="{'pl-5':isCreateOrUpdateAftercareDocs || isCreateOrUpdateConsentPage}"
            />
            <div
              v-if="isShopPages"
              class="md:hidden vx-logo cursor-pointer flex items-center"
              @click="redirectToShopHome"
            >
              <img
                class="mr-2 fill-current text-primary"
                style="width: 28px"
                :src="require('../../../../../public/Group-543.svg')"
              />
              <img
                :src="require('../../../../../public/Group-544.svg')"
                width="100px"
              />
            </div>
          </div>


          <div v-if="isShopPages" class="hidden md:flex flex-col">
            <span>Delivery to</span>
            <ClinicSwap />
          </div>

          <SearchInput v-if="isShopPages" class="hidden md:block w-full"/>

          <ClinicSetupBankDetail />

          <!-- <bookmarks :navbarColor="navbarColor" v-if="windowWidth >= 992" /> -->

          <vs-spacer v-if="!isShopPages"/>

          <!-- <i18n /> -->

          <!-- <search-bar /> -->
          <div class="flex flex-row justify-items-end items-center" :class="{'pr-5':(isCreateOrUpdateAftercareDocs || isCreateOrUpdateConsentPage)}">
            <whats-new
              :class="{ 'ml-1': isShopPages }"></whats-new>

            <template v-if="!isIframe">
              <cart-drop-down v-if="showCart" />
            </template>
           <!--         <ClinicNotificationDropDown />-->

           <!-- <clinic-notification-drop-down /> -->

            <clinic-profile-drop-down />
          </div>
        </div>


        <!-- aftercare form -->
        <div v-if="isCreateOrUpdateAftercareDocs" class="w-full">
          <vs-divider v-if="!aftercareFormSubmissionCompleted"></vs-divider>
          <AftercareNavbar />
        </div>
        <!-- end aftercare form -->

        <!-- consent form -->
        <div v-if="isCreateOrUpdateConsentPage" class="w-full">
          <vs-divider v-if="!consentFormSubmissionCompleted"></vs-divider>
          <ConsentFormNavbar />
        </div>
        <!-- end consent form -->

        <div class="flex items-center w-full md:hidden px-5 justify-between bg-white py-2" v-if="isShopPages"  >
          <Navbar v-if="isShopPages" class="md:hidden border-0 pt-3"/>
          <SearchInput class="w-full justify-end flex" />
        </div>
        <vs-divider v-if="isShopPages" class="hidden md:block" />
        <Navbar v-if="isShopPages" class="hidden md:block border-0 pt-3"/>
        <div id="deliver_to" class=" alert-index px-5 py-3 flex md:hidden absolute justify-between w-full items-center gap-2 box-border z-0 " style="background: #F9F9F9; top:95px;" v-if="isShopPages" >
            <ClinicSwap title="Deliver to:" class="px-5"/>
        </div>
        <BasicAlert id="alert_banner_mobile" class="absolute md:static items-center top-alert alert-index" v-if="isShopPages"/>
        <!-- <div>
        </div> -->

      </div>
      </vs-navbar>
    </div>
  </div>
</template>

<script>
// import Bookmarks from "../components/Bookmarks.vue";
// import I18n from "../components/I18n.vue";
// import SearchBar from "../components/SearchBar.vue";
import CartDropDown from "../components/org-owner/ClinicCartDropDown.vue";
// import ClinicNotificationDropDown from "../components/clinic/ClinicNotificationDropDown.vue";
import ClinicProfileDropDown from "../components/clinic/ClinicProfileDropDown.vue";
import ClinicSetupBankDetail from "../components/basic/BasicSetupBankDetail.vue";
import ClinicNotificationDropDown from "../components/clinic/ClinicNotificationDropDown";
import ClinicSwap from "../../../../views/components/shop/clinic.vue";
import SearchInput from "../../../../views/components/shop/Search.vue";
import BasicAlert from "../../alert-banner/basicAlertBanner.vue";
import Navbar from "../../../../views/components/shop/Navbar.vue";
import AftercareNavbar from "../../../../views/components/aftercare/Navbar.vue";
// import LDClient from "launchdarkly-js-client-sdk";
import { mapActions } from "vuex";
import ConsentFormNavbar from "../../../../views/components/consent-forms/Navbar.vue";
import WhatsNew from "@/layouts/components/navbar/components/WhatsNew.vue";

export default {
  name: "TheClinicNavbarVertical",
  props: {
    navbarColor: {
      type: String,
      default: "#fff",
    },
  },
  data: () => ({
    clinicCompanyName: "",
    showCart: false,
    isIframe: false,
    showTitle: false,
  }),
  components: {
    ClinicNotificationDropDown,
    // Bookmarks,
    // I18n,
    // SearchBar,
    CartDropDown,
    // ClinicNotificationDropDown,
    ClinicProfileDropDown,
    ClinicSetupBankDetail,
    ClinicSwap,
    SearchInput,
    BasicAlert,
    Navbar,
    AftercareNavbar,
    ConsentFormNavbar,
    WhatsNew
  },
  computed: {
    navbarColorLocal() {
      return this.$store.state.theme === "dark" && this.navbarColor === "#fff"
        ? "#10163a"
        : this.navbarColor;
    },
    verticalNavMenuWidth() {
      return this.$store.state.verticalNavMenuWidth;
    },
    textColor() {
      return {
        "text-white":
          (this.navbarColor != "#10163a" &&
            this.$store.state.theme === "dark") ||
          (this.navbarColor != "#fff" && this.$store.state.theme !== "dark"),
      };
    },
    windowWidth() {
      return this.$store.state.windowWidth;
    },

    // NAVBAR STYLE
    // eslint-disable-next-line vue/return-in-computed-property
    classObj() {
      if (this.verticalNavMenuWidth == "default") return "navbar-default";
      else if (this.verticalNavMenuWidth == "reduced") return "navbar-reduced";
      else if (this.verticalNavMenuWidth) return "navbar-full";
    },
    isShopPages() {
      return `${this.$route.path}`.includes("/shop") || `${this.$route.path}`.includes("/product") || `${this.$route.path}`.includes("/checkout");
    },
    isCreateOrUpdateConsentPage() {
      if (!`${this.$route.path}`.includes("/consent-forms")) return false;
      return `${this.$route.path}`.includes("/create") || `${this.$route.path}`.includes("/details")
    },
    consentFormSubmissionCompleted() {
      return this.$store.getters["consentForms/isSubmissionCompleted"];
    },
    isCreateOrUpdateAftercareDocs() {
      if (!`${this.$route.path}`.includes("/aftercare")) return false;
      return `${this.$route.path}`.includes("/create") || `${this.$route.path}`.includes("/details");
    },
    aftercareFormSubmissionCompleted() {
      return this.$store.getters["aftercare/isSubmissionCompleted"]
    },
  },
  // beforeMount: function () {
  //   const { badge: key, email } = this.$store.state.AppActiveUser;
  //   var user = {
  //     key: key,
  //     email: email,
  //   };
  //   this.ldClient = LDClient.initialize(process.env.VUE_APP_LDCLIENT_KEY, user);
  //   this.ldClient.on("ready", this.onLaunchDarklyReady);
  //   this.ldClient.on("change", this.onLaunchDarklyReady);
  // },
  methods: {
    // Set mapActions fetchUserPermission
    ...mapActions("admin", ["fetchUserPermission"]),

    async isShopEnabled() {
      // Set organizationId from localStorage
      const organizationId = localStorage.getItem(
          `${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`
        );

      // response
      const { data: permissions } = await this.fetchUserPermission({
        userId: this.$store.state.AppActiveUser.id,
        organizationId,
      })

      // Verify permission name is Shop
      if (permissions.some(obj => obj.permission.name === 'Shop') && !this.showCloseCta) {
        this.showCart = true
      }

    },
    //  onLaunchDarklyReady() {
    //   this.showCart = this.ldClient.variation("cart", false);
    //  }

    showSidebar() {
      this.$store.commit("TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE", true);
    },
    redirectToShopHome(){
      const userType = JSON.parse(
          localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`)
      ).userType;
      const role = localStorage.getItem(
          `${process.env.VUE_APP_ACCESS_TOKEN_NAME}Role`
      );
      let routerName = "";
      console.log(userType, "userType")
      if (role === "Org Owner") {
          routerName = "OrgOwnerOrderCenter";
      } else {
          if (userType === "doctor") {
              routerName = "DoctorOrderCenter";
          }
          else {
              routerName = "NurseOrderCenter";
          }
      }
      this.$router.push({
          name: routerName});
    },

    closeIframeView() {
      const windowTemp = window;
      windowTemp.webkit.messageHandlers.jsMessageHandler.postMessage(
        'close-screen'
      );
    },
  },
  mounted() {
    this.isShopEnabled()

    // Check if the page is loaded inside an iframe
    this.isIframe = window.top !== window.self;
    this.showTitle = this.$route.name === 'resources';
  },
  watch: {
    "this.$store.state.AppActiveUser.clinicName": function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.clinicCompanyName = this.$store.state.AppActiveUser.clinicName;
      }
    },
  },
  created() {
    this.clinicCompanyName = this.$store.state.AppActiveUser.clinicName;

    if (
      ["subClinic", "clinic"].includes(this.$store.state.AppActiveUser.userType)
    ) {
      this.$store
        .dispatch(
          "clinic/fetchClinicsDetail",
          this.$store.state.AppActiveUser.parentClinicId
        )
        .then((res) => {
          this.clinicCompanyName = res.data.data.clinicName;
        });
    }
  },
  unmounted () {
    window.removeEventListener('scroll', this.handleScroll);
  },
};
</script>

<style lang="scss" scoped>
#content-area {
  background-color: white;
}
.title {
  min-height: 3rem;
}
.sticky {
  position: sticky;
  top: 0;
  z-index: 9999;
  border-bottom: 1px solid #ececec;
}

.home-link{
  background-color: rgba(222,239,234,.34);
  width: 34px;
  height: 34px;
  min-width: 34px;
}
.padder{
  @media screen and (max-width: 768px) {
    padding: .8rem 1rem
  }
}

.search-wrapper {
  width: 100%;
  max-width: 600px;
}

.navbar-custom {

  &.shop-nav .vs-con-items {
    @apply flex-col
  }

  @media screen and (max-width: 768px) {
    border-radius: 0px !important;
    padding: 0px !important;
  }

}

.shop-wrapper{
  margin-bottom: -5rem !important;
}

.shop-wrapper-fixed-search{
  margin-bottom: 9rem;
}
.arrow-spacer {
  margin-bottom: 13rem;
}
@media screen and (max-width: 768px) {
  .shop-wrapper-fixed-search{
    margin-bottom: 3rem;
  }
  .arrow-spacer {
    margin-bottom: 6rem;
  }
}

.top-alert {
  @media (max-width: 768px) {
    top: 133px;
  }
}

.alert-index {
  z-index: -999 !important;
}


.shop-alert-margin{
    // margin-top: 2.5rem !important;
    @media screen and (min-width: 768px){
        margin-top: 0rem !important;
    }
  }
</style>
